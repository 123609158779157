// web_salesOrder_distributorOrderDetails 查看分销商产品订单详情
const __request = require(`@/lib/data-service/default/__request/`)

/**
 * 订单详情
 */
export default function web_salesOrder_distributorOrderDetails(val) {
    let pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/salesOrder/distributorOrderDetails"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}