// web_tourismFullReduction_selectByOrderNo 根据订单编号查询活动信息
const __request = require(`@/lib/data-service/default/__request/`)

/**
 * 订单详情
 */
export default function web_tourismFullReduction_selectByOrderNo(val) {
    let pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/tourismFullReduction/selectByOrderNo"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}