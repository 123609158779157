const __request = require(`./__request/__request_contentType_json`);

// 旅游支付
const pay = (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/web/common/pay/toPayment',
    data:data
  }

  return __request(pParameter)
}
export default pay


