const __request = require(`@/lib/data-service/default/__request/`)

/**
 *切位订单详情
 */
export default function getInternational(val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/common/buyerTourCutOrder/getCutOrderDetail"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}