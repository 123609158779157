import Q from 'q';
// import detail from "@/lib/data-service/default/web_salesOrder_tourOrderDetails";
import detail from "@/lib/data-service/default/web_salesOrder_distributorOrderDetails";
import scrollTop from '@/lib/common-service/scrollTop'
import queryAccountDetail from '@/lib/data-service/flight/wallet_account_queryAccountDetail'
import pay from '@/lib/data-service/default/web_common_pay_yeepay'
import PaymentMethodSelector from '@/lib/common-service/getPayModule';

import orderTeamDetails from '@/lib/data-service/default/web_common_buyerTourCutOrder_getCutOrderDetail'
import web_tourismFullReduction_selectByOrderNo
    from '@/lib/data-service/default/web_tourismFullReduction_selectByOrderNo'
import toPayment from '@/lib/data-service/default/toPayment';
import web_common_pay_toPayment from '@/lib/data-service/default/web_common_pay_toPayment';
import ccbBankPay from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/call_ccb_pc_data_interface'
import moment from 'moment'

export default {
    components: {
        PaymentMethodSelector,
    },
    data() {
        return {
            detail: {},
            payRadio: '',
            btnShow: false,
            payDetail: {},
            dialogVisible: false,
            orderType: '0',
            orderInfoData: {},
            orderAmount: 0,
            discountTit: '',
            discountCont: {}
        }
    },
    props: ['typePay'],
    methods: {
        getDetail(id) {
            return detail({
                id: id
            }).then(res => {
                scrollTop(300)
                return new Promise((resolve => resolve(res.result)))
            })
        },
        getPayMoney(val) {
            queryAccountDetail().then(res => {
                this.payDetail = res
                res.usableAmount > val && res.passwordStatus == 1 ? this.payRadio = '4' : this.btnShow = true
            })
        },
        newPaySubmit(orderAmount) {
            const __this = this;
            let path = this.$route.query.superior

            this.$nextTick(() => {
                this.$refs.aPaymentMethodSelector.init({
                    get_params() {
                        const p = {
                            companyId: __this.detail.companyId || __this.detail.buyerId,
                            clubId: __this.detail.clubId,
                            orderNo: __this.detail.orderNo || __this.detail.cutOrderNo,
                            businessContent: __this.detail.businessContent,
                            subject: __this.detail.productName,
                            orderAmount: orderAmount,
                            isUseCredit: __this.detail.isUseCredit,
                            clientType: 1,//客户端类型：1.b2b，2.b2c，3.差旅,默认为1
                        };
                        return Q.resolve(p);
                    },

                    count_down: {
                        enable: false,//是否启用倒计时，默认值为true
                        pay_remaining_seconds: 0, //支付剩余时间，以秒为单位
                    },

                    //支付成功事件处理方法
                    pay_successful_event_handler() {
                        // __this.dialogVisible = true
                        __this.$router.push({name: path})
                    },
                    //支付失败事件处理方法
                    pay_failed_event_handler() {
                        __this.dialogVisible = true
                        __this.$message({type: "warning", message: "支付失败，请重新支付！"})
                    },
                });
            })
        },
        paySubmit(value, bankId) {
        },
        getOrderTeamDetails(cutOrderNo) {
            orderTeamDetails({
                cutOrderNo
            }).then(res => {
                scrollTop(300)
                this.orderAmount = this.$route.query.cutOrder == 3 ? res.depositAmount : detail.orderAmount - detail.depositAmount
                this.orderInfoData = res
                this.detail = res
                this.newPaySubmit(this.orderAmount)
            })
        }
    },

    created() {
        let id = this.$route.query.id
        let cutOrderNo = this.$route.query.cutOrderNo,
            type = this.$route.query.type
        this.orderType = this.$route.query.cutOrder ? this.$route.query.cutOrder : '0'
        cutOrderNo ? this.getOrderTeamDetails(cutOrderNo) :
            this.getDetail(id).then(res => {
                // this.$route.query.type ? this.orderAmount = res.noDepositAmount ? res.noDepositAmount : res.paidAmount > 0 ? res.orderAmount - res.paidAmount : res.orderAmount : 0
                this.$route.query.type ? this.orderAmount = res.noDepositAmount ? res.noDepositAmount : res.paidAmount > 0 ? res.actualOrderAmount - res.paidAmount : res.actualOrderAmount : 0
                this.detail = res
                type ? this.newPaySubmit(this.orderAmount) : ''
                // 获取优惠说明
                web_tourismFullReduction_selectByOrderNo({orderNo: this.detail.orderNo}).then(res => {
                    let selectInfo = res.tourismFullReductionBuyerResult
                    if (selectInfo !== null && Object.keys(selectInfo).length > 0) {
                        // this.discountTit =  selectInfo.tit
                        // let rule = selectInfo.rule.split('。')
                        this.discountCont = selectInfo
                    }
                })
            })
    },
    filters: {
        formatDate(value) {
            if (value) {
                return moment(value).format('YYYY-MM-DD');
            } else {
                return ''
            }

        }
    }
}
