const __request = require(`@/lib/data-service/default/__request/`)

/**
 * 支付
 */
export default function pay(val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/common/pay/yeepay"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}