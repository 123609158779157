const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 接口文档地址：
// http://192.168.0.12:8080/doc.html#/所有接口/payment-controller/toPaymentUsingPOST
const web_common_pay_toPayment = (pParameter) => {
  if (!pParameter) pParameter = {};

  const businessType = pParameter.businessType || ``;
  const issuerId = pParameter.issuerId || ``;
  const orderId = pParameter.orderId || ``;
  const orderType = pParameter.orderType || ``;
  const password = pParameter.password || ``;
  const paymentChannel = pParameter.paymentChannel || ``;
  const subject = pParameter.subject || ``;
  const tradeNo = pParameter.tradeNo || ``;

  const p = {
    method: 'post',
    urlSuffix: '/web/common/pay/toPayment',
    data: {
      businessType,
      issuerId,
      orderId,
      orderType,
      password,
      paymentChannel,
      subject,
      tradeNo,
    },
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      const resultMap = data.resultMap;
      if (!resultMap) {
        const msg = 'web_common_pay_toPayment parameter error, code=001';
        console.error(msg);
        return Q.reject({msg: msg});
      }

      // const ALIPAY = resultMap.ALIPAY;
      // if (!ALIPAY) {
      //   const msg = 'web_common_pay_toPayment parameter error, code=002';
      //   console.error(msg);
      //   return Q.reject({msg: msg});
      // }

      // const WECHAT = resultMap.WECHAT;
      // if (!WECHAT) {
      //   const msg = 'web_common_pay_toPayment parameter error, code=003';
      //   console.error(msg);
      //   return Q.reject({msg: msg});
      // }

      // const tradeNo = resultMap.tradeNo;
      // if (!tradeNo) {
      //   const msg = 'web_common_pay_toPayment parameter error, code=004';
      //   console.error(msg);
      //   return Q.reject({msg: msg});
      // }

      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;
};
module.exports = web_common_pay_toPayment;
